require('../css/style.css');

window.addEventListener('DOMContentLoaded', run);

async function run(e) {

    document.body.addEventListener('click', e => {

        if (e.target.classList.contains('scroll-to-link')) {
            scrollTo(e);
        }
    });
}

function scrollTo(e) {

    typeof e.preventDefault === 'function' && e.preventDefault();
    const link = e.target;
    const id = link.href.slice(link.href.lastIndexOf('#') + 1);
    const element = document.getElementById(id);
    // element.scrollIntoView({ behavior: 'smooth' });
    scrollToElement(element);

    if (link.dataset.tab) {
        document.getElementById(link.dataset.tab).click();
    }

    toggleMobileMenu(false);
}

function scrollToElement(element, behavior = 'smooth') {

    const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
    const finalOffset = element.getBoundingClientRect().top + scrollTop;

    window.scrollTo({
        top: finalOffset,
        behavior
    });
}



function toggleReadMore(clicked, options) {

    const toggleArea = clicked.closest(options.parent).querySelector(options.area);

    if (toggleArea.clientHeight) {
        toggleArea.style.height = 0;
        if (options.text) {
            const text = options.textCollapsed || 'Read More';
            options.text === 'self'
                ? clicked.textContent = text
                : clicked.querySelector(options.text).textContent = text;
        }
        if (options.image) clicked.querySelector(options.image).style.transform = 'rotate(0deg)';

        if (typeof options.onCollapsed === 'function') {
            options.onCollapsed.call(null, clicked, options);
        }
    } else {

        const wrapper = toggleArea.querySelector(options.wrapper || 'div');

        // Handle any nested read mores.
        // This will trigger when device is mobile and there is nested desktop read more.
        if (Array.isArray(options.nested)) {
            options.nested.forEach(selector => {
                const nestedAreas = wrapper.querySelectorAll(selector);
                if (nestedAreas.length) {
                    nestedAreas.forEach(nestedArea => nestedArea.style.height = 'auto');
                }
            });
        }

        toggleArea.style.height = wrapper.clientHeight + 'px';

        if (options.text) {
            const text = options.textExpanded || 'Collapse';
            options.text === 'self'
                ? clicked.textContent = text
                : clicked.querySelector(options.text).textContent = text;
        }

        if (options.image) clicked.querySelector(options.image).style.transform = 'rotate(180deg)';

        if (typeof options.onExpanded === 'function') {
            options.onExpanded.call(null, clicked, options);
        }
    }
}

var swiper = new Swiper(".mySwiper", {
    autoHeight: true,
    spaceBetween: 30,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
  });

function isMobile() {
    const detector = document.querySelector('.mobile-detector');
    const styles = window.getComputedStyle(detector);
    return styles.getPropertyValue('display') === 'none';
}

